<h1 matDialogTitle>{{ 'UNIT_DETAIL.STATUS_UNIT' | translate }} {{unitNumber}}</h1>
<ng-container>
  <div fxLayout="column" fxLayoutAlign="center start" class="mb-20">
    <div>{{ 'UNIT_DETAIL.CONFIRM_PASSWORD' | translate }}</div>
  </div>

  <div fxLayout="column">
    <div fxLayout="row" fxFlex="1 0 auto" fxLayoutAlign="start center">
      <!-- Reason -->
      <mat-form-field appearance="outline" [fxFlex]="100">
        <mat-label>{{ 'UNIT_DETAIL.COMMENT' | translate }}</mat-label>
        <textarea matInput [(ngModel)]="comment" (keyup)="autoGrowTextZone($event)" required autocomplete="off"></textarea>
        <mat-error>{{ 'UNIT_DETAIL.COMMENT' | translate }} {{ 'UNIT_DETAIL.IS_REQ' | translate }}</mat-error>
      </mat-form-field>
    </div>
    <div fxLayout="row" fxFlex="1 0 auto" fxLayoutAlign="start center">
      <!-- Password -->
        <mat-form-field appearance="outline" [fxFlex]="100">
            <mat-label>{{ 'UNIT_DETAIL.PASSWORD' | translate }}</mat-label>
            <input matInput [type]="inputType" [(ngModel)]="password" required autocomplete="off" >
            <mat-icon matSuffix class="secondary-text" *ngIf="inputType == 'password'" (click)="inputType = 'text'" style="cursor: pointer;">visibility</mat-icon>
            <mat-icon matSuffix class="secondary-text" *ngIf="inputType == 'text'" (click)="inputType = 'password'" style="cursor: pointer;">visibility_off</mat-icon>
            <mat-error>{{ 'UNIT_DETAIL.PASSWORD' | translate }} {{ 'UNIT_DETAIL.IS_REQ' | translate }}</mat-error>
        </mat-form-field>
    </div>
  </div>

</ng-container>
<div mat-dialog-actions class="pt-24" fxLayout="row wrap" fxLayoutAlign="end end">
  <button mat-button (click)="close(false)">{{ 'UNIT_DETAIL.CLOSE' | translate }}</button>
  <button mat-button [disabled]="!password || !comment || isLoading" (click)="save()" color='accent'>{{ 'UNIT_DETAIL.SAVE' | translate }}</button>
</div>