import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { FuseConfigService } from "@fuse/services/config.service";

@Component({
    selector: "app-redirected",
    templateUrl: "./redirected.component.html",
    styleUrls: ["./redirected.component.scss"],
})
export class RedirectedComponent implements OnInit {
    constructor(
        private activatedRoute: ActivatedRoute,
        private fuseConfigService: FuseConfigService,
    ) {}

    ngOnInit() {
        // Redirect to the smart locks page
        localStorage.setItem("smartLocksCode", this.activatedRoute.snapshot.queryParams["code"]);

        this.fuseConfigService.config = {
            layout: {
                navbar: {
                    hidden: true,
                },
                toolbar: {
                    hidden: true,
                },
                footer: {
                    hidden: true,
                },
                sidepanel: {
                    hidden: true,
                },
            },
        };
    }
}