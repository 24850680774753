import { Component, OnInit, ViewEncapsulation, OnDestroy } from '@angular/core';
import { ProgressSpinnerService } from './progress-spinner.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';


@Component({
  selector: 'app-progress-spinner',
  templateUrl: './progress-spinner.component.html',
  styleUrls: ['./progress-spinner.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ProgressSpinnerComponent implements OnInit, OnDestroy {

  bufferValueSpinner: number;
  modeSpinner: 'determinate' | 'indeterminate' | 'buffer' | 'query';
  valueSpinner: number;
  visibleSpinner: boolean;

  private _unsubscribeAll: Subject<any>;

  /**
   * Constructor
   *
   *   @param {ProgressSpinnerService} _progressSpinnerService
   */
  constructor(
    private _progressSpinnerService: ProgressSpinnerService
  ) {
    this._unsubscribeAll = new Subject();
  }

  ngOnInit(): void {
    this._progressSpinnerService.bufferValue
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((bufferValue) => {
        this.bufferValueSpinner = bufferValue;
      });

    // Mode
    this._progressSpinnerService.mode
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((mode) => {
        this.modeSpinner = mode;
      });

    this._progressSpinnerService.value
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((value) => {
        this.valueSpinner = value;
      });

    // Visible
    this._progressSpinnerService.visible
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((visible) => {
        this.visibleSpinner = visible;
      });
  }

  ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }


}
