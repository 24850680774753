import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { Subject } from 'rxjs/Subject';
import * as moment from 'moment';
import { fuseConfig } from '../../../fuse-config';
@Injectable({
    providedIn: 'root'
})
export class ProfileService {
    private subject;
    private residentChange;
    public building: any = {};
    public languages: any = [];
    public language: any = "en";
    constructor(private http: HttpClient) {
      this.subject = new Subject();
      this.residentChange = new Subject();
    }

    setLanguage(language): void {
        this.language = language;
        this.subject.next(language);
    }

    subscribe(next, error?, complete?): any {
        return this.subject.subscribe(next, error, complete);
    }

    residentChanged(resident): any {
        this.residentChange.next(resident);
    }

    subscribeResident(next, error?, complete?): any {
        return this.residentChange.subscribe(next, error, complete);
    }

    

    getOwnerHouses(): Observable<any> {
        const token = localStorage.getItem('token') ? localStorage.getItem('token') : sessionStorage.getItem('token');
        var headers = new HttpHeaders().set('authorization', token);
        headers.append('Content-Type', 'application/json');
        headers = headers.append('Login-Origin', 'web');
        return this.http.get( fuseConfig.url + '/owner/unitHouses', {headers: headers, withCredentials: true});
    }

    getRelatives(): Observable<any> {
        const token = localStorage.getItem('token') ? localStorage.getItem('token') : sessionStorage.getItem('token');
        var headers = new HttpHeaders().set('authorization', token);
        headers.append('Content-Type', 'application/json');
        headers = headers.append('Login-Origin', 'web');
        return this.http.get( fuseConfig.url + '/owner/relatives?invited=true&registered=true', {headers: headers, withCredentials: true});
    }

    getProfile(): Observable<any> {
        const token = localStorage.getItem('token') ? localStorage.getItem('token') : sessionStorage.getItem('token');
        var headers = new HttpHeaders().set('authorization', token);
        headers.append('Content-Type', 'application/json');
        return this.http.get(fuseConfig.url + '/profile/getProfile', {headers: headers, withCredentials: true});
    }

    changePassword(model): Observable<any> {
        const token = localStorage.getItem('token') ? localStorage.getItem('token') : sessionStorage.getItem('token');
        const headers = new HttpHeaders().set('authorization', token);
        headers.append('Content-Type', 'application/json');
        return this.http.put(fuseConfig.url + '/profile/changePassword', model, {headers: headers, withCredentials: true});
    }

    changeName(model): Observable<any> {
        const token = localStorage.getItem('token') ? localStorage.getItem('token') : sessionStorage.getItem('token');
        const headers = new HttpHeaders().set('authorization', token);
        headers.append('Content-Type', 'application/json');
        return this.http.put(fuseConfig.url + '/profile/changeName', model, {headers: headers, withCredentials: true});
    }

    changePhone(model): Observable<any> {
        const token = localStorage.getItem('token') ? localStorage.getItem('token') : sessionStorage.getItem('token');
        const headers = new HttpHeaders().set('authorization', token);
        headers.append('Content-Type', 'application/json');
        return this.http.put(fuseConfig.url + '/profile/changePhone', model, {headers: headers, withCredentials: true});
    }

    createUserUnit(building, body): Observable<any> {
        const token = localStorage.getItem('token') ? localStorage.getItem('token') : sessionStorage.getItem('token');
        const headers = new HttpHeaders().set('authorization', token);
        headers.append('Content-Type', 'application/json');
        return this.http.post(fuseConfig.url + '/buildingAdmin/BuildingUserUnit/' + building, body,
        {headers: headers, withCredentials: true});
    }

    getResidents(unit): Observable<any> {
        const token = localStorage.getItem('token') ? localStorage.getItem('token') : sessionStorage.getItem('token');
        var headers = new HttpHeaders().set('authorization', token);
        headers.append('Content-Type', 'application/json');
        headers = headers.append('Login-Origin', 'web');
        return this.http.get( fuseConfig.url + '/owner/getUnitResidents/' + unit, {headers: headers, withCredentials: true});
    }

    createResidentUser(body): Observable<any> {
        const token = localStorage.getItem('token') ? localStorage.getItem('token') : sessionStorage.getItem('token');
        const headers = new HttpHeaders().set('authorization', token);
        var data = {...body};
        data.img = data.img != null ? data.img.includes('data:image/png;base64') ? data.img.replace('data:image/png;base64,', '') : data.img.includes('data:image/jpeg;base64') ? data.img.replace('data:image/jpeg;base64,', '') : null : null;
        headers.append('Content-Type', 'application/json');
        return this.http.post( fuseConfig.url + '/owner/createResidentUser', data, {headers: headers, withCredentials: true});
    }

    changePhoto(body): Observable<any> {
        const token = localStorage.getItem('token') ? localStorage.getItem('token') : sessionStorage.getItem('token');
        const headers = new HttpHeaders().set('authorization', token);
        var data = {...body};
        data.img = data.img != null ? data.img.includes('data:image/png;base64') ? data.img.replace('data:image/png;base64,', '') : data.img.includes('data:image/jpeg;base64') ? data.img.replace('data:image/jpeg;base64,', '') : null : null;
        headers.append('Content-Type', 'application/json');
        return this.http.put( fuseConfig.url + '/profile/changePhoto', data, {headers: headers, withCredentials: true});
    }

    changeLanguage(body): Observable<any> {
        const token = localStorage.getItem('token') ? localStorage.getItem('token') : sessionStorage.getItem('token');
        const headers = new HttpHeaders().set('authorization', token);
        headers.append('Content-Type', 'application/json');
        return this.http.put( fuseConfig.url + '/profile/changeLanguage', body, {headers: headers, withCredentials: true});
    }

    getResidentRelations(): Observable<any> {
        const token = localStorage.getItem('token') ? localStorage.getItem('token') : sessionStorage.getItem('token');
        const headers = new HttpHeaders().set('authorization', token);
        headers.append('Content-Type', 'application/json');
        return this.http.get( fuseConfig.url + '/owner/residentRelation', {headers: headers, withCredentials: true});
    }

    getPhoneCarrier(): Observable<any> {
        const token = localStorage.getItem('token') ? localStorage.getItem('token') : sessionStorage.getItem('token');
        const headers = new HttpHeaders().set('authorization', token);
        headers.append('Content-Type', 'application/json');
        return this.http.get(fuseConfig.url + '/shared/phone_carrier', { headers: headers, withCredentials: true });
    }

    updateResident(body): Observable<any> {
        const token = localStorage.getItem('token') ? localStorage.getItem('token') : sessionStorage.getItem('token');
        const headers = new HttpHeaders().set('authorization', token);
        headers.append('Content-Type', 'application/json');
        return this.http.put( fuseConfig.url + '/owner/resident', body, {headers: headers, withCredentials: true});
    }

    updateUnitPin(body): Observable<any> {
        const token = localStorage.getItem('token') ? localStorage.getItem('token') : sessionStorage.getItem('token');
        const headers = new HttpHeaders().set('authorization', token);
        headers.append('Content-Type', 'application/json');
        return this.http.put( fuseConfig.url + '/owner/unitPin', body, {headers: headers, withCredentials: true});
    }

    getDealer(): Observable<any> {
        const token = localStorage.getItem('token') ? localStorage.getItem('token') : sessionStorage.getItem('token');
        const headers = new HttpHeaders().set('authorization', token);
        headers.append('Content-Type', 'application/json');
        return this.http.get(fuseConfig.url + '/buildingCreator/dealer/user', { headers: headers, withCredentials: true });
    }

    getDealerUsers(): Observable<any> {
        const token = localStorage.getItem('token') ? localStorage.getItem('token') : sessionStorage.getItem('token');
        const headers = new HttpHeaders().set('authorization', token);
        headers.append('Content-Type', 'application/json');
        return this.http.get(fuseConfig.url + '/buildingCreator/dealerTechs/user', { headers: headers, withCredentials: true });
    }

    getCountries(): Observable<any> {
        const token = localStorage.getItem('token') ? localStorage.getItem('token') : sessionStorage.getItem('token');
        const headers = new HttpHeaders().set('authorization', token);
        headers.append('Content-Type', 'application/json');
        return this.http.get(fuseConfig.url + '/buildingAdmin/countries', { headers: headers, withCredentials: true });
    }

    getCountryStates(id): Observable<any> {
        const token = localStorage.getItem('token') ? localStorage.getItem('token') : sessionStorage.getItem('token');
        const headers = new HttpHeaders().set('authorization', token);
        headers.append('Content-Type', 'application/json');
        return this.http.get(fuseConfig.url + '/buildingAdmin/states/' + id, { headers: headers, withCredentials: true });
    }

    createDealerTech(body): Observable<any> {
        const token = localStorage.getItem('token') ? localStorage.getItem('token') : sessionStorage.getItem('token');
        const headers = new HttpHeaders().set('authorization', token);
        headers.append('Content-Type', 'application/json');
        return this.http.post(fuseConfig.url + '/buildingCreator/dealerTech/user', body, { headers: headers, withCredentials: true });
    }

    updateDealerTech(body): Observable<any> {
        const token = localStorage.getItem('token') ? localStorage.getItem('token') : sessionStorage.getItem('token');
        const headers = new HttpHeaders().set('authorization', token);
        headers.append('Content-Type', 'application/json');
        return this.http.put(fuseConfig.url + '/buildingCreator/dealerTech/user', body, { headers: headers, withCredentials: true });
    }

    updateDealer(body): Observable<any> {
        const token = localStorage.getItem('token') ? localStorage.getItem('token') : sessionStorage.getItem('token');
        const headers = new HttpHeaders().set('authorization', token);
        headers.append('Content-Type', 'application/json');
        return this.http.put(fuseConfig.url + '/buildingCreator/dealer/user', body, { headers: headers, withCredentials: true });
    }

    toggleDealerStatus(body): Observable<any> {
        const token = localStorage.getItem('token') ? localStorage.getItem('token') : sessionStorage.getItem('token');
        const headers = new HttpHeaders().set('authorization', token);
        headers.append('Content-Type', 'application/json');
        return this.http.put(fuseConfig.url + '/buildingCreator/dealer/changeStatus', body, { headers: headers, withCredentials: true });
    }

    getBuildingSettings(id): Observable<any> {
        const token = localStorage.getItem("token")
            ? localStorage.getItem("token")
            : sessionStorage.getItem("token");
        const headers = new HttpHeaders().set("authorization", token);
        headers.append("Content-Type", "application/json");
        return this.http.get(fuseConfig.url + "/owner/buildingSettings/" + id, {
            headers: headers,
            withCredentials: true,
        });
    }

    getSmartKioskSettings(id): Observable<any> {
        const token = localStorage.getItem("token")
            ? localStorage.getItem("token")
            : sessionStorage.getItem("token");
        const headers = new HttpHeaders().set("authorization", token);
        headers.append("Content-Type", "application/json");
        return this.http.get(fuseConfig.url + "/owner/kioskSmart/" + id, {
            headers: headers,
            withCredentials: true,
        });
    }

    deleteResidents(body: any): Observable<any> {
        const token = localStorage.getItem("token")
            ? localStorage.getItem("token")
            : sessionStorage.getItem("token");
        const headers = new HttpHeaders().set("authorization", token);
        headers.append("Content-Type", "application/json");
        return this.http.post(
            fuseConfig.url + `/owner/residents/kioskSmart/delete`,
            body,
            {
                headers: headers,
                withCredentials: true,
            }
        );
    }

    getPhotoResidents(building: string, resident:string): Observable<any> {
        const token = localStorage.getItem('token') ? localStorage.getItem('token') : sessionStorage.getItem('token');
        const headers = new HttpHeaders().set('authorization', token);
        headers.append('Content-Type', 'application/json');
        return this.http.get( fuseConfig.url + `/owner/residents/kioskSmart/photo/${resident}/${building}`, {headers: headers, withCredentials: true});
    }

    getDataOwner(building: string, unit:string): Observable<any> {
        const token = localStorage.getItem('token') ? localStorage.getItem('token') : sessionStorage.getItem('token');
        const headers = new HttpHeaders().set('authorization', token);
        headers.append('Content-Type', 'application/json');
        return this.http.get( fuseConfig.url + `/owner/residents/user/${unit}/${building}`, {headers: headers, withCredentials: true});
    }

    deletePhotoResidents(building: string, resident:string): Observable<any> {
        const token = localStorage.getItem('token') ? localStorage.getItem('token') : sessionStorage.getItem('token');
        const headers = new HttpHeaders().set('authorization', token);
        headers.append('Content-Type', 'application/json');
        return this.http.delete( fuseConfig.url + `/owner/residents/kioskSmart/photo/${resident}/${building}`, {headers: headers, withCredentials: true});
    }

    putSetPincodes(body: any, remove: boolean, manual?:boolean): Observable<any> {
        const token = localStorage.getItem("token")
            ? localStorage.getItem("token")
            : sessionStorage.getItem("token");
        const headers = new HttpHeaders().set("authorization", token);
        headers.append("Content-Type", "application/json");
        return this.http.put(
            fuseConfig.url + `/owner/residents/kioskSmart/setPincode?removeAll=${remove}&manual=${manual? manual:false}`,
            body,
            {
                headers: headers,
                withCredentials: true,
            }
        );
    }

    updateResidentOnSmartKiosk(body): Observable<any> {
        const token = localStorage.getItem("token")
            ? localStorage.getItem("token")
            : sessionStorage.getItem("token");
        const headers = new HttpHeaders().set("authorization", token);
        headers.append("Content-Type", "application/json");
        return this.http.put(
            fuseConfig.url + `/owner/residents/kioskSmart/`,
            body,
            {
                headers: headers,
                withCredentials: true,
            }
        );
    }

    updatePhotoResidentOnSmartKiosk(body): Observable<any> {
        const token = localStorage.getItem("token")
            ? localStorage.getItem("token")
            : sessionStorage.getItem("token");
        const headers = new HttpHeaders().set("authorization", token);
        headers.append("Content-Type", "application/json");
        return this.http.post(
            fuseConfig.url + `/owner/residents/kioskSmart/photo`,
            body,
            {
                headers: headers,
                withCredentials: true,
            }
        );
    }

    postResidents(body: any): Observable<any> {
        const token = localStorage.getItem("token")
            ? localStorage.getItem("token")
            : sessionStorage.getItem("token");
        const headers = new HttpHeaders().set("authorization", token);
        headers.append("Content-Type", "application/json");
        return this.http.post(
            fuseConfig.url + `/owner/residents/kioskSmart/`,
            body,
            {
                headers: headers,
                withCredentials: true,
            }
        );
    }

    postKioskSmartSettings(body: any): Observable<any> {
        const token = localStorage.getItem("token")
            ? localStorage.getItem("token")
            : sessionStorage.getItem("token");
        const headers = new HttpHeaders().set("authorization", token);
        headers.append("Content-Type", "application/json");
        return this.http.put(
            fuseConfig.url + `/owner/resident/kioskSmart/settings`,
            body,
            {
                headers: headers,
                withCredentials: true,
            }
        );
    }

}
