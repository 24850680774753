import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VideocallPipComponent } from './videocall-pip.component';
import { MatButtonModule, MatIconModule, MatSnackBarModule } from '@angular/material';
import { TranslateModule } from '@ngx-translate/core';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { WebrtcClientComponent } from './webrtc-client/webrtc-client.component';

@NgModule({
  declarations: [VideocallPipComponent, WebrtcClientComponent],
  imports: [
    CommonModule,
    TranslateModule,
    MatButtonModule,
    MatIconModule,
    DragDropModule,
    MatSnackBarModule,
  ],
  exports: [VideocallPipComponent]
})
export class VideocallPipModule { }
