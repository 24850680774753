import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { Subject } from 'rxjs/Subject';
import * as moment from 'moment';
import { fuseConfig } from '../../fuse-config';
@Injectable({
    providedIn: 'root'
})
export class AuthService {
    private subject;
    public isAuthenticated = false;
    constructor(
        private http: HttpClient,
        // private _fuseConfigService: FuseConfigService
    ) {
      this.subject = new Subject();

    }
    
    subscribe(next, error?, complete?) {
        return this.subject.subscribe(next, error, complete);
    }

    login(model): Observable<any> {
        const headers = new HttpHeaders().set('Login-Origin', 'web');
        return this.http.post(`${fuseConfig.url}/users/authenticate`, model, {headers: headers, withCredentials: true});
    }

    logout(): Observable<any> {
        this.subject.next();
        const token = localStorage.getItem('token') ? localStorage.getItem('token') : sessionStorage.getItem('token');
        const headers = new HttpHeaders().set('authorization', token);
        headers.append('Content-Type', 'application/json');
        return this.http.get(fuseConfig.url + '/session/destroy/', {headers: headers, withCredentials: true});
    }

    requestRegisterCode(email): Observable<any> {
        const headers = new HttpHeaders();
        headers.append('Content-Type', 'application/json');
        return this.http.post(fuseConfig.url + '/users/requestCode', {email: email});
    }

    confirmRegisterCode(model): Observable<any> {
        const headers = new HttpHeaders();
        headers.append('Content-Type', 'application/json');
        return this.http.post(fuseConfig.url + '/users/finishRegister', model, {headers});
    }

    requestPasswordCode(email): Observable<any> {
        const headers = new HttpHeaders();
        headers.append('Content-Type', 'application/json');
        return this.http.post(fuseConfig.url + '/users/requestPasswordChange', {email: email});
    }

    confirmPasswordCode(model): Observable<any> {
        const headers = new HttpHeaders();
        headers.append('Content-Type', 'application/json');
        return this.http.post(fuseConfig.url + '/users/finishPasswordChange', model, {headers});
    }

    tryCaptcha(data): Observable<any> {
        const headers = {
             headers: new HttpHeaders({
                'Content-Type': 'application/x-www-form-urlencoded',
                'App-Version': fuseConfig.App_Version,
                'App-Authorization': fuseConfig.App_Authorization,
            }),
            'withCredentials': true,
        };
        return this.http.post(fuseConfig.captchaUrl + 'try', data, headers);
    }

    /**
     * Validate username or email
     * @param user
     * @returns {Observable<any>}
     **/
    validateUsername(username): Observable<any> {
        const headers = {
            headers: new HttpHeaders({
                "Content-Type": "application/json",
                "App-Version": fuseConfig.App_Version,
                "App-Authorization": fuseConfig.App_Authorization,
            }),
            withCredentials: true,
        };

        return this.http.post(
            `${fuseConfig.url}/users/validate`,
            { username },
            headers,
        );
    }

    /**
     * Request OTP
     * @param username
     * @returns {Observable<any>}
     * */
    requestOtp(username: string): Observable<any> {
        const headers = {
            headers: new HttpHeaders({
                "Content-Type": "application/json",
                "App-Version": fuseConfig.App_Version,
                "App-Authorization": fuseConfig.App_Authorization,
            }),
            withCredentials: true,
        };

        return this.http.post(
            `${fuseConfig.url}/users/requestOTP`,
            { username },
            headers,
        );
    }
}