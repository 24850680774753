export const locale = {
    lang: "en",
    data: {
        NAV: {
            ACTIONS: "Actions",
            LIGHT: "Light Theme",
            DARK: "Dark Theme",
            INVITATIONS: {
                TITLE: "Events",
                BADGE: "25",
            },
            INVITE: {
                TITLE: "Send Invitation",
            },
            SCANS: {
                TITLE: "Visitor Logs",
            },
            DASHBOARD: {
                TITLE: "Dashboard",
            },
            GUESTS: {
                TITLE: "Guests",
            },
            ECONTACTS: {
                TITLE: "Emergency Contacts",
            },
            INSTRUCTIONS: {
                TITLE: "Instructions",
            },
            VEHICLES: {
                TITLE: "Vehicles",
            },
            PETS: {
                TITLE: "Pets",
            },
            ACCOUNT: "Account",
            PROFILE: {
                TITLE: "Profile",
            },
            SECURITY: "Security",
            SCAN: {
                TITLE: "Scan",
            },
            CONTROL: {
                TITLE: "Control",
            },
            MONITOR: {
                TITLE: "Live Access Monitor",
            },
            EVENTS: {
                TITLE: "Events",
            },
            LICENSE_SCAN: {
                TITLE: "Scan License",
            },
            TODAY: {
                TITLE: "Today Guests",
            },
            BADMIN: "Site Admin",
            REQUESTS: {
                TITLE: "User Requests",
            },
            BUILDINGINFO: {
                TITLE: "Site Information",
            },
            STAFF: {
                TITLE: "Site Staff",
            },
            OWNERS: {
                TITLE: "Units",
            },
            DEPARTMENTS: {
                TITLE: "Departments",
            },
            GADMIN: "GouTru Admin",
            BUILDINGS: {
                TITLE: "Sites",
            },
            USERS: {
                TITLE: "Users",
            },
            GTCONNECTOR: {
                TITLE: "Goutru Connector",
            },
            DOORS: {
                TITLE: "Doors",
            },
            CAMERAS: {
                TITLE: "Cameras",
            },
            CARDS: {
                TITLE: "Cards",
            },
            "CARDS-CDVI": {
                TITLE: "Cards",
            },
            REPORT: {
                TITLE: "Site",
                TITLE2: "Report",
            },
            GUEST_REPORT: {
                TITLE: "Guest / Vendor",
            },
            VREPORT: {
                TITLE: "Vehicles",
            },
            VREPORTNEW: {
                TITLE: "Vehicle Logs",
            },
            GUESTTYPE: {
                TITLE: "Guest type",
            },
            LOGOUT: {
                TITLE: "Logout",
                YOU_SURE: "Are you sure you want to logout?",
                NO: "No",
                YES: "Yes",
            },
            MYPROFILE: {
                TITLE: "My Profile",
            },
            REPORTS: {
                TITLE: "Reports",
                ACCESS: {
                    TITLE: "Visitor Logs",
                },
                ACCESS_MON: {
                    TITLE: "Access Monitoring",
                },
                TAGS: {
                    TITLE: "Cards/Tags",
                },
                INCIDENT_REPORT: {
                    TITLE: "Incident Report",
                },
                EVENT_REPORT: {
                    TITLE: "Events",
                }
            },
            DEALERS: {
                TITLE: "Dealers",
            },
            LICENSES: {
                TITLE: "Licenses",
            },
            KIOSKS: {
                TITLE: "Kiosks",
            },
            KIOSKS_APPROVE: {
                TITLE: "Approve Activation",
            },
            SMART_KIOSKS: {
                TITLE: "Settings",
            },
            SMART_REPORTS: {
                TITLE: "Reports",
            },
            AREAS: {
                TITLE: "Areas",
            },
            MEMBERS: {
                TITLE: "Members",
            },
            HOLIDAYS: {
                TITLE: "Holidays",
            },
            CONTROL_PANEL: {
                TITLE: "Control Panel",
            },
            ACCESS_LEVEL: {
                TITLE: "Access Level",
            },
            SCHEDULES: {
                TITLE: "Schedules",
            },
            PANELS: {
                TITLE: "Panels",
            },
            DEALER: "Dealer",
            DEALERTECH: "Dealer Tech",
            SPEED_VIOLATIONS: {
                TITLE: "Speed Violations",
                WORKING_ON: "In Process",
                SETTINGS: "Settings",
                EVENTS: "Events",
                LOGS: "Logs",
                VEHICLES: "Speeding Violation Vehicles",
                REPORTS: "Reports",
            },
            SMART_KIOSK: {
                TITLE: "Smart Kiosk",
            },
            GATE_CHANGED_CHECKOUT: 'You have been checked out from previous gate',
            SMART_LOCKS: {
                TITLE: "Smart Locks",
                SETTINGS: "Settings",
                REPORTS: "Reports",
            },
        },
    },
};