import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { Subject } from 'rxjs/Subject';
import * as moment from 'moment';
import { fuseConfig } from '../../fuse-config';
@Injectable({
    providedIn: 'root'
})
export class OwnerService {
    private subject;
    public unit = null;
    public selectedUnit = null;
    public fromDashboard = false;
    constructor(private http: HttpClient) {
      this.subject = new Subject();
    }

    subscribe(next, error?, complete?): any {
        return this.subject.subscribe(next, error, complete);
    }
    
    selectUnit(unit): any {
        this.unit = unit;
        this.subject.next(unit);
    }

    getUnitHouses(): Observable<any> {
        const token = localStorage.getItem('token') ? localStorage.getItem('token') : sessionStorage.getItem('token');
        var headers = new HttpHeaders().set('authorization', token);
        headers = headers.append('Content-Type', 'application/json');
        headers = headers.append('Login-Origin', 'web');

        return this.http.get( fuseConfig.url + '/owner/unitHouses/', {headers: headers, withCredentials: true});
    }
    
    getBuildingSettings(id): Observable<any> {
        const token = localStorage.getItem('token') ? localStorage.getItem('token') : sessionStorage.getItem('token');
        const headers = new HttpHeaders().set('authorization', token);
        headers.append('Content-Type', 'application/json');
        return this.http.get(fuseConfig.url + '/owner/buildingSettings/' + id, { headers: headers, withCredentials: true });
    }

    getContacts(unit, contact): Observable<any> {
        const token = localStorage.getItem('token') ? localStorage.getItem('token') : sessionStorage.getItem('token');
        const headers = new HttpHeaders().set('authorization', token);
        headers.append('Content-Type', 'application/json');

        const filter = {
            contact: contact
        };
        let search = '';
        let slice = false;
        if (filter.contact !== '') {
            search = '?filter=';
            slice = true;
        }
        if (filter.contact !== '') {
            search += `contact:eq:${filter.contact}|`;
        }

        if (slice === true) {
            search = search.slice(0, -1);
        }
        return this.http.get( fuseConfig.url + '/owner/getEmergencyContacts/' + unit + search, {headers: headers, withCredentials: true});
    }

    addContact(body): Observable<any> {
        const token = localStorage.getItem('token') ? localStorage.getItem('token') : sessionStorage.getItem('token');
        const headers = new HttpHeaders().set('authorization', token);
        var data = {...body};
        data.img = data.img != null ? data.img.includes('data:image/png;base64') ? data.img.replace('data:image/png;base64,', '') : data.img.includes('data:image/jpeg;base64') ? data.img.replace('data:image/jpeg;base64,', '') : null : null;
        headers.append('Content-Type', 'application/json');
        return this.http.post( fuseConfig.url + '/owner/addEmergencyContact', data, {headers: headers, withCredentials: true});
    }

    updateContact(body): Observable<any> {
        const token = localStorage.getItem('token') ? localStorage.getItem('token') : sessionStorage.getItem('token');
        const headers = new HttpHeaders().set('authorization', token);
        var data = {...body};
        data.img = data.img != null ? data.img.includes('data:image/png;base64') ? data.img.replace('data:image/png;base64,', '') : data.img.includes('data:image/jpeg;base64') ? data.img.replace('data:image/jpeg;base64,', '') : null : null;
        headers.append('Content-Type', 'application/json');
        return this.http.put( fuseConfig.url + '/owner/updateEmergencyContact', data, {headers: headers, withCredentials: true});
    }

    deleteContact(contact_id): Observable<any> {
        const token = localStorage.getItem('token') ? localStorage.getItem('token') : sessionStorage.getItem('token');
        const headers = new HttpHeaders().set('authorization', token);
        headers.append('Content-Type', 'application/json');
        return this.http.delete( fuseConfig.url + '/owner/deleteEmergencyContact/'+contact_id, {headers: headers, withCredentials: true});
    }

    getCurrentInstruction(unit): Observable<any> {
        const token = localStorage.getItem('token') ? localStorage.getItem('token') : sessionStorage.getItem('token');
        const headers = new HttpHeaders().set('authorization', token);
        headers.append('Content-Type', 'application/json');
        return this.http.get( fuseConfig.url + '/owner/getCurrentInstruction/' + unit, {headers: headers, withCredentials: true});
    }

    postUnitInstruction(data): Observable<any> {
        const token = localStorage.getItem('token') ? localStorage.getItem('token') : sessionStorage.getItem('token');
        const headers = new HttpHeaders().set('authorization', token);
        headers.append('Content-Type', 'application/json');
        return this.http.post( fuseConfig.url + '/owner/postUnitInstruction', data, {headers: headers, withCredentials: true});
    }

    getVehicles(unit, resident, vehicle, user): Observable<any> {
        const token = localStorage.getItem('token') ? localStorage.getItem('token') : sessionStorage.getItem('token');
        const headers = new HttpHeaders().set('authorization', token);
        headers.append('Content-Type', 'application/json');

        const filter = {
            vehicle: vehicle,
            user: user
        };
        let search = '';
        let slice = false;
        if (filter.vehicle !== '' || filter.user !== '') {
            search = '?filter=';
            slice = true;
        }
        if (filter.vehicle !== '') {
            search += `vehicle:eq:${filter.vehicle}|`;
        }
        if (filter.user !== '') {
            search += `user:eq:${filter.user}|`;
        }

        if (slice === true) {
            search = search.slice(0, -1);
        }

        return this.http.get( fuseConfig.url + '/owner/getUnitVehicles/' + unit + search, {headers: headers, withCredentials: true});
    }

    addVehicle(body): Observable<any> {
        const token = localStorage.getItem('token') ? localStorage.getItem('token') : sessionStorage.getItem('token');
        const headers = new HttpHeaders().set('authorization', token);
        var data = { ...body };
        data.img = data.img != null ? data.img.includes('data:image/png;base64') ? data.img.replace('data:image/png;base64,', '') : data.img.includes('data:image/jpeg;base64') ? data.img.replace('data:image/jpeg;base64,', '') : null : null;
        headers.append('Content-Type', 'application/json');
        return this.http.post(fuseConfig.url + '/owner/addUnitVehicle', data, { headers: headers, withCredentials: true });
    }

    updateVehicle(body): Observable<any> {
        const token = localStorage.getItem('token') ? localStorage.getItem('token') : sessionStorage.getItem('token');
        const headers = new HttpHeaders().set('authorization', token);
        var data = { ...body };
        data.img = data.img != null ? data.img.includes('data:image/png;base64') ? data.img.replace('data:image/png;base64,', '') : data.img.includes('data:image/jpeg;base64') ? data.img.replace('data:image/jpeg;base64,', '') : null : null;
        headers.append('Content-Type', 'application/json');
        return this.http.put(fuseConfig.url + '/owner/updateUnitVehicle', data, { headers: headers, withCredentials: true });
    }

    deleteVehicle(vehicle_id): Observable<any> {
        const token = localStorage.getItem('token') ? localStorage.getItem('token') : sessionStorage.getItem('token');
        const headers = new HttpHeaders().set('authorization', token);
        headers.append('Content-Type', 'application/json');
        return this.http.delete(fuseConfig.url + '/owner/deleteUnitVehicle/' + vehicle_id, { headers: headers, withCredentials: true });
    }

    getPets(unit, pet): Observable<any> {
        const token = localStorage.getItem('token') ? localStorage.getItem('token') : sessionStorage.getItem('token');
        const headers = new HttpHeaders().set('authorization', token);
        headers.append('Content-Type', 'application/json');

        const filter = {
            pet: pet
        };
        let search = '';
        let slice = false;
        if (filter.pet !== '') {
            search = '?filter=';
            slice = true;
        }
        if (filter.pet !== '') {
            search += `pet:eq:${filter.pet}|`;
        }

        if (slice === true) {
            search = search.slice(0, -1);
        }

        return this.http.get( fuseConfig.url + '/owner/getUnitPets/' + unit + search, {headers: headers, withCredentials: true});
    }

    getNotificationRules(unit): Observable<any> {
        const token = localStorage.getItem('token') ? localStorage.getItem('token') : sessionStorage.getItem('token');
        const headers = new HttpHeaders().set('authorization', token);
        headers.append('Content-Type', 'application/json');
        return this.http.get( fuseConfig.url + '/owner/notification_rules/' + unit, {headers: headers, withCredentials: true});
    }

    updateNotificationRules(data): Observable<any> {
        const token = localStorage.getItem('token') ? localStorage.getItem('token') : sessionStorage.getItem('token');
        const headers = new HttpHeaders().set('authorization', token);
        headers.append('Content-Type', 'application/json');
        return this.http.put( fuseConfig.url + '/owner/notification_rules', data, {headers: headers, withCredentials: true});
    }

    getCommunityStaff(id): Observable<any> {
        const token = localStorage.getItem('token') ? localStorage.getItem('token') : sessionStorage.getItem('token');
        const headers = new HttpHeaders().set('authorization', token);
        headers.append('Content-Type', 'application/json');
        return this.http.get( fuseConfig.url + '/owner/getCommunityStaff/' + id, {headers: headers, withCredentials: true});
    }

    getPositions(): Observable<any> {
        const token = localStorage.getItem('token') ? localStorage.getItem('token') : sessionStorage.getItem('token');
        const headers = new HttpHeaders().set('authorization', token);
        headers.append('Content-Type', 'application/json');
        return this.http.get( fuseConfig.url + '/shared/getBuildingPositions/', {headers: headers, withCredentials: true});
    }

    getDashboard(unit): Observable<any> {
        const token = localStorage.getItem('token') ? localStorage.getItem('token') : sessionStorage.getItem('token');
        const headers = new HttpHeaders().set('authorization', token);
        headers.append('Content-Type', 'application/json');
        return this.http.get( fuseConfig.url + '/owner/dashboard/' + unit, {headers: headers, withCredentials: true});
    }

    getLocks(unit: string): Observable<{ locks: any[] }> {
        const token = localStorage.getItem("token")
            ? localStorage.getItem("token")
            : sessionStorage.getItem("token");
        const headers = new HttpHeaders().set("authorization", token);

        return this.http.get<{ locks: any[] }>(
            `${fuseConfig.url}/owner/units/${unit}/locks`,
            { headers: headers, withCredentials: true },
        );
    }

    getLockDetails(lock: string, filter = ""): Observable<{ codes: any[]; logs: any[] }> {
        const token = localStorage.getItem("token")
            ? localStorage.getItem("token")
            : sessionStorage.getItem("token");
        const headers = new HttpHeaders().set("authorization", token);

        return this.http.get<{ codes: any[]; logs: any[] }>(
            `${fuseConfig.url}/owner/locks/${lock}${filter}`,
            { headers: headers, withCredentials: true },
        );
    }

    openDoor(unit: string, lock): Observable<any> {
        const token = localStorage.getItem("token")
            ? localStorage.getItem("token")
            : sessionStorage.getItem("token");
        const headers = new HttpHeaders().set("authorization", token);

        return this.http.post(
            `${fuseConfig.url}/owner/units/${unit}/locks/${lock}/open`,
            {},
            { headers: headers, withCredentials: true },
        );
    }

    createCode(lock: string, code: any) {
        const token = localStorage.getItem("token")
            ? localStorage.getItem("token")
            : sessionStorage.getItem("token");
        const headers = new HttpHeaders().set("authorization", token);

        return this.http.post(
            `${fuseConfig.url}/owner/locks/${lock}/codes`,
            code,
            {
                headers,
                withCredentials: true,
            },
        );
    }

    deleteCode(lock: string, codes: string[]) {
        const token = localStorage.getItem("token")
            ? localStorage.getItem("token")
            : sessionStorage.getItem("token");
        const headers = new HttpHeaders().set("authorization", token);

        return this.http.put(
            `${fuseConfig.url}/owner/locks/${lock}/codes-multiple-delete`,
            { codes },
            {
                headers,
                withCredentials: true,
            },
        );
    }

    checkSynced(lock: string, code: string) {
        const token = localStorage.getItem("token")
            ? localStorage.getItem("token")
            : sessionStorage.getItem("token");
        const headers = new HttpHeaders().set("authorization", token);

        return this.http.put(
            `${fuseConfig.url}/owner/locks/${lock}/codes/${code}`,
            {},
            {
                headers,
                withCredentials: true,
            },
        );
    }
}