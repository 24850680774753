<div class="navbar-top" [ngClass]="fuseConfig.layout.navbar.secondaryBackground">

    <div class="logo">
        <!-- <img class="logo-icon" src="assets/images/logos/got.png"> -->
        <img class="logo-icon" src="assets/images/logos/logo_white_green_arrow-01.png">
        <!-- <span class="logo-text secondary-text">GouTru</span> -->
    </div>

    <div class="buttons">

        <button mat-icon-button class="toggle-sidebar-folded"
                (click)="toggleSidebarFolded()" fxHide.lt-lg>
            <mat-icon class="secondary-text">menu</mat-icon>
        </button>

        <button mat-icon-button class="toggle-sidebar-opened"
                (click)="toggleSidebarOpened()" fxHide.gt-md>
            <mat-icon class="secondary-text">arrow_back</mat-icon>
        </button>

    </div>

</div>


<div class="navbar-scroll-container" [ngClass]="fuseConfig.layout.navbar.primaryBackground"
     fusePerfectScrollbar [fusePerfectScrollbarOptions]="{suppressScrollX: true}">

    <div class="user" fxLayout="column" [ngClass]="fuseConfig.layout.navbar.secondaryBackground">

        <div class="h3 username">{{fuseConfig.user.name + ' ' + fuseConfig.user.lastname}}</div>
        <div class="h5 email hint-text mt-8">{{fuseConfig.user.email}}</div>
        <!-- <div *ngIf="fuseConfig.isSecurity === true" class="h5 email hint-text">
            <mat-form-field>
                <mat-select placeholder="Gate">
                    <mat-option>Test</mat-option>
                </mat-select>
            </mat-form-field>
        </div> -->
<!-- 
        <div *ngIf="fuseConfig.isSecurity === false && fuseConfig.isTowerAdmin === false && fuseConfig.isBuildingAdmin === false" class="h3 username mt-8">
            House Owner
        </div> -->
        
        <div class="avatar-container" [ngClass]="fuseConfig.layout.navbar.primaryBackground">
            <img class="avatar" *ngIf="photo == null" src="assets/images/avatars/profile.jpg">
            <img [src]="photo | safeHtml" *ngIf="photo != null" class="avatar">
        </div>

    </div>

    <div class="navbar-content">
        <fuse-navigation class="material2" layout="vertical"></fuse-navigation>
    </div>

</div>