<mat-toolbar class="p-0 mat-elevation-z1">

    <div fxFlex fxFill fxLayout="row" fxLayoutAlign="start center">

        <div fxFlex="1 0 auto" fxLayout="row" fxLayoutAlign="start center">

            <button mat-icon-button class="navbar-toggle-button"
                    *ngIf="!hiddenNavbar && !rightNavbar" (click)="toggleSidebarOpen('navbar')" fxHide.gt-md>
                <mat-icon class="secondary-text">menu</mat-icon>
            </button>

            <div class="toolbar-separator" *ngIf="!hiddenNavbar && !rightNavbar" fxHide.gt-md></div>

            <div fxLayout="row" fxLayoutAlign="start center" *ngIf="horizontalNavbar">
                <div class="logo ml-16">
                    <img class="logo-icon" src="assets/images/logos/got.png">
                </div>
            </div>

            <div class="px-8 px-md-16" *ngIf = "showGates === true">
                <!-- <fuse-shortcuts [navigation]="navigation"></fuse-shortcuts> -->
                <mat-form-field class="mr-8" fxFlex = "1 1 100%">
                    <mat-select [(ngModel)]="currentGate" placeholder="{{'TOOLBAR.GATE' | translate}}" (selectionChange)="gateChange($event)" required>
                        <mat-option *ngFor="let gate of gates" [value]="gate" >
                            {{gate.text}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div fxLayout="row" class="h3" fxLayoutAlign="space-around center" 
            [ngClass]="{'blinkingAccess': (newEntry && lastEntry.description == 'Access granted'), 
                        'blinkingRejected': (newEntry && lastEntry.description != 'Access granted'), 
                        'noBlink': !newEntry}" *ngIf = "lastEntry">
                <div fxLayout="column">
                    <div class="lineHeight">{{'TOOLBAR.LENTRY' | translate}}:</div>
                    <div>{{lastEntry.door_name}}</div>
                </div>

                <div class="ml-8" >
                    <img class="button-img entry-img mr-0 mr-sm-16" (click)="retrivePhoto()"
                    matTooltip="View Photo"
                    [matTooltipPosition]="'right'"
                    [src]="lastEntryPhoto">
                </div>
                
                <div fxLayout="column" class="ml-8 mr-8" fxLayoutAlign="start">
                    <div class="lineHeight">{{lastEntry.user_name + ' - Card Used: ' + lastEntry.card}}</div>
                    <div [ngStyle]="{'color': lastEntry.description === 'Access granted' ? 'green' : 'red' }">{{lastEntry.description}}</div>
                </div>
                
                <div fxLayout="column">
                    <div class="lineHeight">{{lastEntry.car}}</div>
                    <div>{{lastEntry.date}}</div>
                </div>
            </div>
        </div>

        <div fxLayout="column" class="h3 accent-color-time" fxLayoutAlign="none" >
            <div class="lineHeight">{{currentDate}}</div>
            <div>{{currentTime | async }}</div>
        </div>

        <div class="" fxFlex="0 1 auto" fxLayout="row" fxLayoutAlign="start center">

            <button mat-button [matMenuTriggerFor]="userMenu"
                    class="user-button">
                <div fxLayout="row" fxLayoutAlign="center center">
                    <img [src]="photo | safeHtml" *ngIf="photo != null" class="avatar mr-0 mr-sm-16">
                    <img class="avatar mr-0 mr-sm-16" *ngIf="photo == null" src="assets/images/avatars/profile.jpg">
                    <span class="username mr-12" fxHide fxShow.gt-sm>{{name}}</span>
                    <mat-icon class="s-16" fxHide.xs>keyboard_arrow_down</mat-icon>
                </div>
            </button>

            <mat-menu #userMenu="matMenu" [overlapTrigger]="false">

                <button mat-menu-item (click)="changeTheme()">
                    <mat-icon>{{darkTheme ? 'wb_sunny' : 'wb_iridescent'}}</mat-icon>
                    <span *ngIf="!darkTheme">{{ 'NAV.DARK' | translate }}</span>
                    <span *ngIf="darkTheme">{{ 'NAV.LIGHT' | translate }}</span>
                </button>

                <button *ngIf="!showGates" [routerLink]="'/profile'" mat-menu-item>
                    <mat-icon>account_circle</mat-icon>
                    <span>{{ 'NAV.MYPROFILE.TITLE' | translate }}</span>
                </button>

                <button (click)="logout()" mat-menu-item class="">
                    <mat-icon>exit_to_app</mat-icon>
                    <span>{{ 'NAV.LOGOUT.TITLE' | translate }}</span>
                </button>

            </mat-menu>

            <div class="toolbar-separator"></div>

            <!-- <fuse-search-bar (input)="search($event)"></fuse-search-bar> -->

            <div class="toolbar-separator"></div>

            <button mat-button fxHide fxShow.gt-xs
                    class="language-button"
                    [matMenuTriggerFor]="languageMenu">
                <div fxLayout="row" fxLayoutAlign="center center">
                    <img class="flag mr-8" [src]="'assets/icons/flags/'+selectedLanguage.flag+'.png'">
                    <span class="iso text-uppercase">{{selectedLanguage.id}}</span>
                </div>
            </button>

            <mat-menu #languageMenu="matMenu" [overlapTrigger]="false">

                <button mat-menu-item *ngFor="let lang of languages" (click)="setLanguage(lang)">
                    <span fxLayout="row" fxLayoutAlign="start center">
                        <img class="flag mr-16" [src]="'assets/icons/flags/'+lang.flag+'.png'">
                        <span class="iso">{{lang.title}}</span>
                    </span>
                </button>

            </mat-menu>

            <!-- <div class="toolbar-separator" fxHide fxShow.gt-xs></div>

            <button mat-icon-button
                    class="quick-panel-toggle-button"
                    (click)="toggleSidebarOpen('quickPanel')"
                    aria-label="Toggle quick panel">
                <mat-icon class="secondary-text">format_list_bulleted</mat-icon>
            </button> -->

            <div class="toolbar-separator" *ngIf="!hiddenNavbar && rightNavbar" fxHide fxShow.gt-xs></div>

            <button mat-icon-button class="navbar-toggle-button"
                    *ngIf="!hiddenNavbar && rightNavbar" (click)="toggleSidebarOpen('navbar')" fxHide.gt-md>
                <mat-icon class="secondary-text">menu</mat-icon>
            </button>

        </div>

    </div>

</mat-toolbar>