import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError  } from 'rxjs';
import { Router } from '@angular/router';
import { map, catchError } from 'rxjs/operators';
@Injectable()
export class SessionInterceptor implements HttpInterceptor {
  constructor(private router: Router) {}
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            catchError((err: HttpErrorResponse) => {
            // console.log('ERROR INTERCEPTOR');
            if (err.status === 403 && (err.error.message.code === 'S0001' || err.error.message.code === 'S9999')) {
                // console.log(err);
                this.router.navigate(['/auth/login']);
                return throwError(err);
            } else if (err.status === 403 && (err.error.message.code === 'S0002')) {
                this.router.navigate(['/profile']);
                return throwError(err);
            }
            return throwError(err);
        }));
    }
}