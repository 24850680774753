<div *ngIf="gateId" id="videos">
  <div class="call-wrapper" cdkDrag [style.visibility]="incomingCall || acceptedCall ? 'visible' : 'hidden'">
    <div id="incoming-call" *ngIf="incomingCall" [@appear]>
      <span>{{ 'VIDEOCALL_PIP.INCOMING_CALL' | translate }}</span>

      <button class="circular video-icon" mat-fab disabled>
        <mat-icon [inline]="true">videocam</mat-icon>
      </button>

      <div fxFlex fxLayoutAlign="center center">
        <button class="circular accept-button" mat-mini-fab (click)="acceptCall()">
          <mat-icon [inline]="true">call_filled</mat-icon>
        </button>

        <button class="circular reject-button" mat-mini-fab (click)="hideCall()" color="warn">
          <mat-icon [inline]="true">call_end_filled</mat-icon>
        </button>
      </div>
    </div>

    
    <!-- Show call If accepted -->
    <div id="videos-wrapper" *ngIf="acceptedCall" [@visibilityChanged]="acceptedCall ? 'visible' : 'hidden'">
      <!-- Videocall Client -->
      <app-webrtc-client (callEnded)="handleEndCall()"></app-webrtc-client>
    </div>
  </div>
</div>