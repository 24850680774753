export const locale = {
    lang: "es",
    data: {
        NAV: {
            ACTIONS: "Acciones",
            LIGHT: "Tema Claro",
            DARK: "Tema Oscuro",
            INVITATIONS: {
                TITLE: "Eventos",
                BADGE: "25",
            },
            INVITE: {
                TITLE: "Enviar Invitación",
            },
            SCANS: {
                TITLE: "Reg. de Visitantes",
            },
            DASHBOARD: {
                TITLE: "Inicio",
            },
            GUESTS: {
                TITLE: "Invitados",
            },
            ECONTACTS: {
                TITLE: "Contactos de Emergencia",
            },
            INSTRUCTIONS: {
                TITLE: "Instrucciones",
            },
            VEHICLES: {
                TITLE: "Vehículos",
            },
            PETS: {
                TITLE: "Mascotas",
            },
            ACCOUNT: "Cuenta",
            PROFILE: {
                TITLE: "Perfil",
            },
            SECURITY: "Seguridad",
            SCAN: {
                TITLE: "Escaneo",
            },
            CONTROL: {
                TITLE: "Control",
            },
            MONITOR: {
                TITLE: "Monitor de Accesos en Vivo",
            },
            EVENTS: {
                TITLE: "Eventos",
            },
            LICENSE_SCAN: {
                TITLE: "Escaneo de Licencia",
            },
            TODAY: {
                TITLE: "Invitados de Hoy",
            },
            BADMIN: "Admin. Sitio",
            REQUESTS: {
                TITLE: "Solicitudes de Usuario",
            },
            BUILDINGINFO: {
                TITLE: "Información del Sitio",
            },
            STAFF: {
                TITLE: "Empleados del Sitio",
            },
            OWNERS: {
                TITLE: "Unidades",
            },
            DEPARTMENTS: {
                TITLE: "Departamentos",
            },
            GADMIN: "GouTru Admin",
            BUILDINGS: {
                TITLE: "Sitios",
            },
            USERS: {
                TITLE: "Usuarios",
            },
            GTCONNECTOR: {
                TITLE: "Goutru Connector",
            },
            DOORS: {
                TITLE: "Puertas",
            },
            CAMERAS: {
                TITLE: "Cámaras",
            },
            CARDS: {
                TITLE: "Tarjetas",
            },
            "CARDS-CDVI": {
                TITLE: "Tarjetas",
            },
            REPORT: {
                TITLE: "Sitio",
                TITLE2: "Reporte",
            },
            GUEST_REPORT: {
                TITLE: "Invitado / Proveedor",
            },
            VREPORT: {
                TITLE: "Vehiculos",
            },
            VREPORTNEW: {
                TITLE: "Registros de Vehículos",
            },
            GUESTTYPE: {
                TITLE: "Tipos de Invitado",
            },
            LOGOUT: {
                TITLE: "Cerrar sesión",
                YOU_SURE: "¿Está seguro que desea cerrar sesión?",
                NO: "No",
                YES: "Si",
            },
            MYPROFILE: {
                TITLE: "Mi Perfil",
            },
            REPORTS: {
                TITLE: "Reportes",
                ACCESS: {
                    TITLE: "Reg. de Visitantes",
                },
                ACCESS_MON: {
                    TITLE: "Monitor de Accesos",
                },
                TAGS: {
                    TITLE: "Tarjetas/Etiquetas",
                },
                INCIDENT_REPORT: {
                    TITLE: "Reporte de incidentes",
                },
                EVENT_REPORT: {
                    TITLE: "Eventos",
                }
            },
            DEALERS: {
                TITLE: "Proveedores",
            },
            LICENSES: {
                TITLE: "Licencias",
            },
            KIOSKS: {
                TITLE: "Kioscos",
            },
            KIOSKS_APPROVE: {
                TITLE: "Aprobar Activación",
            },
            SMART_KIOSKS: {
                TITLE: "Ajustes",
            },
            SMART_REPORTS: {
                TITLE: "Reportes",
            },
            AREAS: {
                TITLE: "Áreas",
            },
            MEMBERS: {
                TITLE: "Miembros",
            },
            HOLIDAYS: {
                TITLE: "Días Festivos",
            },
            CONTROL_PANEL: {
                TITLE: "Panel de Control",
            },
            ACCESS_LEVEL: {
                TITLE: "Nivel de Acceso",
            },
            SCHEDULES: {
                TITLE: "Horarios",
            },
            PANELS: {
                TITLE: "Paneles",
            },
            DEALER: "Distribuidor",
            DEALERTECH: "Téc. Distribuidor",
            SPEED_VIOLATIONS: {
                TITLE: "Infracciones de velocidad",
                WORKING_ON: "En Proceso",
                SETTINGS: "Configuración",
                EVENTS: "Eventos",
                LOGS: "Registros",
                VEHICLES: "Vehículos con Infracciones",
                REPORTS: "Reportes",
            },
            SMART_KIOSK: {
                TITLE: "Kiosko inteligente",
            },
            GATE_CHANGED_CHECKOUT: 'Se ha hecho Check Out en la puerta anterior',
            SMART_LOCKS: {
                TITLE: "Cerraduras inteligentes",
                SETTINGS: "Configuración",
                REPORTS: "Reportes",
            },
        },
    },
};