<div id="videos">
    <div id="localVideoContainer">
        <video
            #localVideo
            [hidden]="!hasVideo"
            id="localVideo"
            autoplay
            playsinline
            [muted]="true"
            style="transform: scaleX(-1)"
        ></video>
    </div>

    <div id="remoteVideoContainer">
        <video
            #remoteVideo
            id="remoteVideo"
            autoplay="true"
            playsinline
        ></video>
    </div>

    <div class="buttons">
        <button mat-mini-fab (click)="toggleMicrophone()">
            <mat-icon *ngIf="hasAudio" [inline]="true">mic</mat-icon>
            <mat-icon *ngIf="!hasAudio" [inline]="true">mic_off</mat-icon>
        </button>

        <button mat-mini-fab (click)="toggleCamera()">
            <mat-icon *ngIf="hasVideo" [inline]="true">videocam</mat-icon>
            <mat-icon *ngIf="!hasVideo" [inline]="true">videocam_off</mat-icon>
        </button>

        <button mat-mini-fab (click)="hangUp()" color="warn">
            <mat-icon [inline]="true">call_end_filled</mat-icon>
        </button>

        <button
            mat-mini-fab
            (click)="handleSingleClickOpenDoor()"
            (dblclick)="openDoor()"
        >
            <mat-icon [inline]="true">lock_open</mat-icon>
        </button>
    </div>
</div>
